import { urlRegex } from "@/constants/regexps";
import { StyleChatColor, StyleIconSize, StyleSetVariant, agentBuildInFunctions } from "@/types/agent";
import { ValidationSchema } from "@/types/validation/validationSchema";
import { z } from "zod";

export const AgentFormPublishSchema = z.object({
  isConfigured: z.boolean(),
  isPublished: z.boolean(),
  allowedOrigins: z.array(
    z.object({
      domain: z.string(),
    })
  ),
  chatStyle: z.object({
    variant: z.nativeEnum(StyleSetVariant),
    color: z.nativeEnum(StyleChatColor),
    iconSize: z.nativeEnum(StyleIconSize),
  }),
  customBranding: z.object({
    isActive: z.boolean(),
    value: z.string(),
  }),
});

export const AgentFormValidationSchema = z.object({
  name: ValidationSchema.agentNameRequired.max(50, { message: "This field must be within 50 characters." }),
  company: ValidationSchema.customerNameRequired.max(50, { message: "This field must be within 50 characters." }),
  avatar: ValidationSchema.stringRequired,
  llmModel: ValidationSchema.stringRequired,
  temperature: z.number(),
  inMemoryKnowledge: z.object({
    about: z.string(),
    instruction: z.string(),
    onboardingList: z
      .array(
        z.object({
          description: ValidationSchema.stringRequired.max(80, "This field must be within 80 characters."),
          content: ValidationSchema.stringRequired.max(800, "This field must be within 800 characters."),
          _id: z.string().optional(),
          isNew: z.boolean().optional(),
        })
      )
      .refine(
        onboardingItems => {
          const descriptions = onboardingItems.map(item => item.description);
          const hasNoDuplicates = new Set(descriptions).size === descriptions.length;
          return hasNoDuplicates;
        },
        { message: "Onboarding items must be unique." }
      ),
  }),
  definedActions: z.object({
    greeting: z.array(
      z.object({
        message: z.string(),
      })
    ),
  }),
  embedKnowledges: z.object({
    web: z.array(
      z.object({
        creator: z.string().optional(),
        embedIdList: z.array(z.string()).optional(),
        knowledgeType: z.string().optional(),
        source: z.string(),
        filename: z.string().optional(),
        description: z.string().optional(),
        content: z.string().optional(),
        parentId: z.string().optional(),
        status: z.string().optional(),
        createdAt: z.string().optional(),
        updatedAt: z.string().optional(),
        predefined: z.boolean().optional(),
        _id: z.string().optional(),
      })
    ),
    doc: z.array(
      z.union([
        z.object({
          creator: z.string().optional(),
          embedIdList: z.array(z.string()).optional(),
          knowledgeType: z.string().optional(),
          source: z.string().optional(),
          filename: z.string(),
          description: z.string().optional(),
          content: z.string().optional(),
          parentId: z.string().optional(),
          status: z.string().optional(),
          createdAt: z.string().optional(),
          updatedAt: z.string().optional(),
          predefined: z.boolean().optional(),
          _id: z.string().optional(),
          docId: z.string().optional(),
          path: z.string().optional(),
          externalSource: z.string().optional(),
          externalId: z.string().optional(),
        }),
        // This object is used for new folders that come from Google Drive
        z.object({
          isNewFolder: z.boolean(),
          id: z.string(),
          name: z.string(),
          mimeType: z.string(),
          children: z.array(
            z.object({
              id: z.string(),
              name: z.string(),
              mimeType: z.string(),
            })
          ),
        }),
      ])
    ),
    faq: z
      .array(
        z
          .object({
            creator: z.string().optional(),
            embedIdList: z.array(z.string()).optional(),
            knowledgeType: z.string().optional(),
            source: z.string().optional(),
            filename: z.string().optional(),
            description: ValidationSchema.stringRequired.max(200, "This field must be within 200 characters."),
            content: z.string().max(1200, "This field must be within 1200 characters.").optional(),
            parentId: z.string().optional(),
            status: z.string().optional(),
            createdAt: z.string().optional(),
            updatedAt: z.string().optional(),
            predefined: z.boolean(),
            _id: z.string().optional(),
          })
          .refine(data => data.content || data.source, {
            message: "Answer is required.",
            path: ["content"],
          })
      )
      .refine(
        faqItems => {
          const descriptions = faqItems.map(item => item.description);
          const hasNoDuplicates = new Set(descriptions).size === descriptions.length;
          return hasNoDuplicates;
        },
        { message: "Faq items must be unique." }
      ),
    externalHelpers: z.array(
      z.object({
        externalSource: z.string(),
        externalId: z.string(),
        type: z.string(),
        name: z.string(),
        botId: z.string().optional(),
        createdAt: z.string().optional(),
        updatedAt: z.string().optional(),
        _id: z.string().optional(),
      })
    ),
  }),
  buildinFunctions: z.array(z.nativeEnum(agentBuildInFunctions)),
  leadGeneration: z.object({
    enabled: z.boolean(),
    params: z.array(
      z.object({
        parameterName: z.string(),
        parameterDescription: z.string(),
        required: z.boolean(),
        type: z.enum(["integer", "string"]),
        _id: z.string(),
      })
    ),
    emailVendor: z.enum(["", "GMAIL"]),
    webhook: z
      .object({
        enabled: z.boolean(),
        url: z.string(),
        header: z.array(z.string()).optional(),
        method: z.string().optional(),
      })
      .refine(
        data => {
          if (data.enabled) {
            return urlRegex.test(data.url);
          }
          return true;
        },
        {
          message: "Entered value does not match URL format",
          path: ["url"],
        }
      ),
  }),
  publish: AgentFormPublishSchema,
  loadJs: z.boolean().optional(),
});
