import type { AxiosError } from "axios";
import axios from "axios";
import { toast as Toast } from "react-toastify";

export const baseURL = import.meta.env.VITE_BASE_API_URL as string;

type errorData = {
  status: string;
  statusCode: number;
  message: string;
  errors: [];
};

export const restClient = axios.create({
  baseURL,
  timeout: 80000, // increase from 40000 to 80000 for Flux image generation
});

export const authorizeAxiosClient = ({ token }: { token: string }) => {
  restClient.defaults.headers.Authorization = `Token ${token}`;
};

export const unauthorizeAxiosClient = () => {
  restClient.defaults.headers.Authorization = "";
  localStorage.removeItem("token");
};

restClient.defaults.headers.common["x-location"] = JSON.stringify(window?.location);

restClient.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    try {
      if (localStorage.getItem("token")) {
        if (error.response) {
          const errorData = error.response.data as errorData;
          const ignoredErrors = ["Conversation not found", "Agent does not exist"];

          if (ignoredErrors.includes(errorData?.message)) {
            console.log(errorData?.message);
            return;
          }

          const toastId = errorData?.message;

          Toast.error(errorData?.message || "An error occurred", {
            toastId,
            autoClose: 10000,
          });
        } else if (error.request) {
          // The request was made but no response was received
          /* const toastMessage = "No response received from server";
          Toast.error(toastMessage, {
            toastId: toastMessage,
            autoClose: 10000,
          });*/
        } else {
          // Something happened in setting up the request that triggered an Error
          // Toast.warning("An error occurred while setting up the request");
        }
      } else {
        //user is not logged in, no need to display toasts
      }
    } catch (e) {
      Toast.warning("Sorry! An error occurred");
    }
    return Promise.reject(error);
  }
);
